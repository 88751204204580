.root {
  min-height: 100%;
}

.rightSide {
  @media screen and (min-width: 993px) {
    margin-left: 80px;

    &.open {
      margin-left: 200px;
    }
  }
}
