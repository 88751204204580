.sidebar {
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  height: 100%;
  z-index: 101;
}

.sidebarContent {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.sidebarHeader {
  position: relative;
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.sidebarMenu {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.closeIconWrapper {
  position: absolute;
  right: 10px;
}

.closeIcon {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  color: white;
}
