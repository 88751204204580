.root {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 0;
}

.headerActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
}

.trigger {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

// .modal {
//   & :global( .ant-modal-content) {
//     min-height: 78vh;
// }
// }
.modalItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;}