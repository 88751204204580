html,
body {
  height: 100%;
  width: 100%;
  background-color: var(--color-background);
}

#root {
  height: 100%;
  width: 100%;
}

.transition {
  transition: all 0.2s ease-out;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background-color: gray;
}
