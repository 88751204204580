.root {
  line-height: 1.5;
  margin-right: 5px;
  cursor: pointer;
  .trigger {
    font-size: 18px;
    transition: color 0.3s;
    cursor: pointer;
  }

  &.sidebar {
    cursor: default;
    & .trigger {
      cursor: default;
    }
  }
}
