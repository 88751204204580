.logoLink {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoContainer {
  width: 125px;
  height: 50px;
  background-repeat: no-repeat;
}

.logo {
  background-size: contain;         
  background-image: url('../../../assets/images/abracadabra-logo.png');
}

.logoMin {
  width: 80px;
  height: 80px;
  background-size: contain;         
  background-image: url('../../../assets/images/abcd.png');
}
