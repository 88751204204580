.card {
  opacity: 0.8;
}

.form {
  max-width: 300px; 
}

.loginButton {
  width: 100%;
  
}

.secretTokenSwitcher {
  margin-right: 10px;
}

.logoInLoginForm {
  position: absolute;
  z-index: 999;
  transform: translateY(-400px);
  left: 0px;
  top: -7px;
  animation: hat-init 5s ease-in-out forwards;
}

@keyframes hat-init {
  0% {
    transform: translateY(-400px);
  }

  15% {
    transform: translateY(-300px) translateX(100px) rotateZ(-30deg);;
  }

  30% {
    transform: translateY(-240px) translateX(0) rotateZ(60deg);
  }

  45% {
    transform: translateY(-200px) translateX(60px) rotateZ(-60deg);
  }

  60% {
    transform: translateY(-150px) translateX(-40px) rotateZ(60deg);
  }

  75% {
    transform: translateY(-100px) translateX(40px) rotateZ(-60deg);
  }

  90% {
    transform: translateY(-50px) translateX(-20px) rotateZ(60deg);
  }

  100% {
    transform: rotate(322deg) translateY(0) translateX(0);
  }
}